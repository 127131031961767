<template>
<div class="body-content">
    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:16px;line-height:107%;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
<div align="left" style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0.8in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
    <table dir="rtl" style="width: 5.4e+2pt;margin-left:-29.5pt;border-collapse:collapse;border:none;">
        <tbody>
            <tr style="height:50px"></tr>
            <tr>
                <td colspan="2" style="width: 266.25pt;border-width: 1pt;border-style: solid;border-color: white white rgb(217, 217, 217);border-image: initial;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;border:none;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>&quot;اتفاقية</span><span style='font-size:16px;font-family:"Sakkal Majalla";'>&quot; تسويق بالعمولة</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;border:none;'><span dir="LTR" style='font-size:16px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                </td>
                <td colspan="2" style="width: 275.25pt;border-top: 1pt solid white;border-left: 1pt solid white;border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>Affiliate Marketing &ldquo;<strong>Agreement</strong>&rdquo;</span></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 114pt;border-right: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-left: 1pt solid rgb(217, 217, 217);border-image: initial;border-top: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>الشركة</span></p>
                </td>
                <td style="width: 152.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>اي تي كان م.م.ح &quot;اتقان</span><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>&quot;</span></p>
                </td>
                <td style="width: 156pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>ITcan Fz LLE &ldquo;<strong>ITCAN</strong>&rdquo;</span></p>
                </td>
                <td style="width: 119.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Company</span></strong></p>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="width: 266.25pt;border-right: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-left: 1pt solid rgb(217, 217, 217);border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>بيانات المسوق</span></p>
                </td>
                <td colspan="2" style="width: 275.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;background: rgb(217, 217, 217);padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Affiliate</span></strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;<strong>Details</strong></span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 114pt;border-right: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-left: 1pt solid rgb(217, 217, 217);border-image: initial;border-top: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>اسم المسوق&nbsp;</span></p>
                </td>
                <td style="width: 152.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'></span></p>
                </td>
                <td style="width: 156pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><span style='font-size:15px;font-family:"Arial","sans-serif";color:#1D1C1D;background:#F8F8F8;'>{{ userDetails?userDetails.company:'' }}</span></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
                </td>
                <td style="width: 119.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Name of Affiliate</span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="width: 114pt;border-right: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-left: 1pt solid rgb(217, 217, 217);border-image: initial;border-top: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>عنوانه</span></p>
                </td>
                <td style="width: 152.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'></span></p>
                </td>
                <td style="width: 156pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>{{ userDetails?userDetails.address1:'' }}</span></p>
                </td>
                <td style="width: 119.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Address</span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="width: 114pt;border-right: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-left: 1pt solid rgb(217, 217, 217);border-image: initial;border-top: none;padding: 5pt;height: 26.25pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>الجنسية</span></p>
                </td>
                <td style="width: 152.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 26.25pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'></span></p>
                </td>
                <td style="width: 156pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 26.25pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>{{ country?country.name:'' }}</span></p>
                </td>
                <td style="width: 119.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 26.25pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Nationality</span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="width: 114pt;border-right: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-left: 1pt solid rgb(217, 217, 217);border-image: initial;border-top: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>رقم البطاقة الشخصية</span></p>
                </td>
                <td colspan="2" style="width: 308.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;'><span style='font-size:15px;font-family:"Arial","sans-serif";color:#1D1C1D;background:#F8F8F8;'>28909162403153</span></p>
                </td>
                <td style="width: 119.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>ّID. No.</span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="width: 114pt;border-right: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-left: 1pt solid rgb(217, 217, 217);border-image: initial;border-top: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>البريد الالكتروني</span></p>
                </td>
                <td colspan="2" style="width: 308.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;'><span style='font-size:15px;font-family:"Arial","sans-serif";background:#F8F8F8;'>{{ userDetails?userDetails.email:'' }}</span></p>
                </td>
                <td style="width: 119.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Email</span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="width: 114pt;border-right: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-left: 1pt solid rgb(217, 217, 217);border-image: initial;border-top: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>رقم الهاتف الجوال</span></p>
                </td>
                <td colspan="2" style="width: 308.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;'><span dir="LTR" style='font-size:15px;font-family:  "Arial","sans-serif";color:#1D1C1D;background:  #F8F8F8;'>{{ userDetails?userDetails.phone:'' }}</span></p>
                </td>
                <td style="width: 119.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Mobile No.</span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="width: 114pt;border-right: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-left: 1pt solid rgb(217, 217, 217);border-image: initial;border-top: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>حالة التسجيل بموقع اتقان (</span><strong><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>CPX</span></strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>)</span></p>
                </td>
                <td style="width: 152.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><u><span style='font-size:16px;font-family:  "Sakkal Majalla";'>مسجل</span></u></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>تاريخ التسجيل:</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><span style='font-size:15px;font-family:"Arial","sans-serif";color:#1D1C1D;background:#F8F8F8;'>5 أغسطس 2020</span></p>
                </td>
                <td style="width: 156pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><u><span style='font-size:16px;font-family:"Sakkal Majalla";'>Registered</span></u></strong></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Registration date: &nbsp;</span></strong><strong><span style='font-size:15px;font-family:"Arial","sans-serif";color:#1D1C1D;background:#F8F8F8;'>5 August 2020</span></strong></p>
                </td>
                <td style="width: 119.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Registration Status at ITCAN (CPX) Webpage</span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="width: 114pt;border-right: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-left: 1pt solid rgb(217, 217, 217);border-image: initial;border-top: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>اسم المستخدم المسجل &nbsp;</span></p>
                </td>
                <td colspan="2" style="width: 308.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <h1 dir="LTR" style='margin-top:24.0pt;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:81%;font-size:32px;font-family:"Calibri","sans-serif";margin:0in;text-align:  center;background:  #EEF0F2;'><u><span style="font-size:15px;line-height:81%;font-family:Roboto;color:#20252D;background:#F3F3F3;">&nbsp;</span></u><span style='font-size:15px;line-height:81%;font-family:"Arial","sans-serif";background:#F8F8F8;font-weight:normal;'>{{ userDetails?userDetails.email:"" }}</span></h1>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;'><strong><u><span style='font-size:16px;font-family:"Sakkal Majalla";'><span style="text-decoration:   none;">&nbsp;</span></span></u></strong></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;'><strong><u><span style='font-size:16px;font-family:"Sakkal Majalla";'>(Affiliate ID - {{ affiliate_id }})&nbsp;</span></u></strong></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;'><u><span style='font-size:16px;font-family:"Sakkal Majalla";'><span style="text-decoration:none;">&nbsp;</span></span></u></p>
                </td>
                <td style="width: 119.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Username used for the registration</span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="width: 114pt;border-right: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-left: 1pt solid rgb(217, 217, 217);border-image: initial;border-top: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><strong><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></strong></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>الخدمة</span></p>
                </td>
                <td style="width: 152.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>زيادة زوار المواقع وتسويق وتحقيق مبيعات على شبكة الانترنت لعملاء الشركة</span></p>
                </td>
                <td style="width: 156pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom: 0.0001pt;margin-left:0in;line-height: normal;font-size:15px;font-family:"Calibri","sans-serif";text-align: right;border: none;'><span dir="LTR" style='font-size:16px;font-family:  "Sakkal Majalla";'>driving web traffic, performance marketing and generating sales on the world wide web for the Company&apos;s Clients</span></p>
                </td>
                <td style="width: 119.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></strong></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Service</span></strong></p>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="width: 266.25pt;border-right: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-left: 1pt solid rgb(217, 217, 217);border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>عمولة المسوق &nbsp;</span></p>
                </td>
                <td colspan="2" style="width: 275.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;background: rgb(217, 217, 217);padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Affiliate Commission</span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="width: 114pt;border-right: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-left: 1pt solid rgb(217, 217, 217);border-image: initial;border-top: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><strong><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></strong></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><strong><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></strong></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>أنواع العمولة</span></p>
                </td>
                <td style="width: 152.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>تختلف أنواع العمولة مع اختلاف العروض المروج لها.</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>تتواجد العروض على صفحة&nbsp;</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>الشركة &nbsp;بالرابط التالي:&nbsp;</span></p>
                </td>
                <td style="width: 156pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>Payout Models differ based on the promotions</span></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>Promotions are available on the Company&rsquo;s page in the following link:&nbsp;</span></p>
                </td>
                <td style="width: 119.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(217, 217, 217);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></strong></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></strong></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Payout Models</span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="width: 114pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(243, 243, 243);border-right: 1pt solid rgb(217, 217, 217);padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>رابط العروض</span></p>
                </td>
                <td colspan="2" style="width: 308.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(243, 243, 243);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;'><a href="http://join.cpxaffiliate.com/admin/offers"><strong><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";color:#1155CC;'>http://join.cpxaffiliate.com/admin/offers</span></strong></a></p>
                </td>
                <td style="width: 119.25pt;border-top: none;border-left: 1pt solid rgb(217, 217, 217);border-bottom: 1pt solid rgb(243, 243, 243);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Offers Link</span></strong></p>
                </td>
            </tr>
            <tr style="height: 425px"></tr>
            <tr>
                <td colspan="2" style="width: 266.25pt;border-right: 1pt solid rgb(243, 243, 243);border-bottom: 1pt solid rgb(243, 243, 243);border-left: 1pt solid rgb(243, 243, 243);border-image: initial;border-top: none;background: rgb(217, 217, 217);padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:10pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>الشروط والأحكام</span></p>
                </td>
                <td colspan="2" style="width: 275.25pt;border-top: none;border-left: 1pt solid rgb(243, 243, 243);border-bottom: 1pt solid rgb(243, 243, 243);border-right: none;background: rgb(217, 217, 217);padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:10pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Terms and Conditions</span></strong></p>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="width: 266.25pt;border-right: 1pt solid rgb(243, 243, 243);border-bottom: 1pt solid rgb(243, 243, 243);border-left: 1pt solid rgb(243, 243, 243);border-image: initial;border-top: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>تطبق الشروط والأحكام التالية:&nbsp;</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:16px;font-family:  "Sakkal Majalla";'><br>&nbsp;</span><span style='font-size:16px;font-family:"Sakkal Majalla";'>شروط الدفع:&nbsp;</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:16px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                    <ol start="1" style="margin-bottom:25pt;margin-top:0in;" type="1">
                        <li dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:15px;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>سيتم دفع عمولة المسوق بالدولار الأمريكي فقط، باستثناء ما تحدده اتقان وفقًا لتقديرها المطلق.</span></li>
                    </ol>
                    <p dir="RTL" style='margin-top:0in;margin-right:.5in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:.5in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
                    <ol start="2" style="margin-bottom:25pt;margin-top:0in;" type="1">
                        <li dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:15px;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>يتم الدفع للمسوق بعد 30 يومًا بعد تأكيد اتقان للمستحقات وعدد الطلبات المحققة من قبل المسوق وبعد تحديد ما إذا كان المسوق قد التزم بكافة الشروط والأحكام الموجودة على صفحة&nbsp;</span><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>CPX</span><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;الخاصة بشركاء ومسوقين اتقان.</span></li>
                    </ol>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:16px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:16px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                    <ol start="3" style="margin-bottom:25pt;margin-top:0in;" type="1">
                        <li dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:15px;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>تخضع شروط هذه الاتفاقية لتسجيل المسوق على صفحة&nbsp;</span><span dir="LTR" style='font-size:16px;font-family:       "Sakkal Majalla";'>CPX</span><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;والإقرار والموافقة على البنود والشروط والأحكام المنصوص عليها في الصفحة ويتم اعتبارها جزء لا يتجزأ من هذه الاتفاقية وتدرج فيها. في حالة وجود تعارض بين الشروط والأحكام المنصوص عليها في هذه الاتفاقية والشروط والأحكام في صفحة&nbsp;</span><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>CPX</span><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;، تسري شروط وأحكام&nbsp;</span><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>CPX</span><span style='font-size:16px;font-family:"Sakkal Majalla";'>.</span></li>
                    </ol>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:16px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:16px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:16px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'><br>&nbsp; شروط وأحكام&nbsp;</span><strong><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>CPX</span></strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>:</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><strong><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></strong></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;يقر المسوق بأنه قرأ وفهم وبالفعل وافق على الشروط والأحكام المتواجدة على موقع &nbsp;</span><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>CPX</span><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;و للإشارة الشروط والأحكام متواجدة على الرابط أدناه :</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:16px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:16px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:16px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                </td>
                <td colspan="2" style="width: 275.25pt;border-top: none;border-left: 1pt solid rgb(243, 243, 243);border-bottom: 1pt solid rgb(243, 243, 243);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:15pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Sakkal Majalla";'>The following terms and conditions shall apply:</span></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>Payment Terms:</span></strong></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
                    <ol start="1" style="margin-bottom:12pt;margin-top:0in;" type="1">
                        <li dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:15px;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align: left;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>Affiliate Fee payments will be payable in United States Dollars only, except as otherwise determined by ITCAN in its sole discretion.</span></li>
                    </ol>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
                    <ol start="2" style="margin-bottom:12pt;margin-top:0in;" type="1">
                        <li dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:15px;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align: left;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>Affiliate shall be paid after 30 days from ITCAN confirming the generated orders number and after determining if the affiliate abided by and met all the terms and conditions found on the ITCAN&rsquo;s Affiliate Program &ldquo;CPX&rdquo; page.</span></li>
                    </ol>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
                    <ol start="3" style="margin-bottom:12pt;margin-top:0in;" type="1">
                        <li dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:15px;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align: left;'><span style='font-size:16px;font-family:"Sakkal Majalla";'>The terms of this Agreement are subject to the Affiliate registering on the CPX page and acknowledging and agreeing to the terms and conditions set out in the page and shall be included herein by this reference. in the case where there is discrepancy between the Terms and Conditions set out in this Agreement and CPX terms and conditions, the latter shall prevail.</span></li>
                    </ol>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>CPX Terms and Conditions:</span></strong></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></strong></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Sakkal Majalla";'>The Affiliate acknowledges that he read and understood and indeed agreed to the terms and conditions on the CPX site. For reference, the terms and conditions can be found at the following link:</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 114pt;border-right: 1pt solid rgb(243, 243, 243);border-bottom: 1pt solid rgb(243, 243, 243);border-left: 1pt solid rgb(243, 243, 243);border-image: initial;border-top: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><strong><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></strong></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>رابط الشروط والأحكام</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><strong><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></strong></p>
                </td>
                <td colspan="2" style="width: 308.25pt;border-top: none;border-left: 1pt solid rgb(243, 243, 243);border-bottom: 1pt solid rgb(243, 243, 243);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;'><a href="http://join.cpxaffiliate.com/terms"><span style='font-size:16px;font-family:"Sakkal Majalla";color:#1155CC;'>http://join.cpxaffiliate.com/terms</span></a><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
                </td>
                <td style="width: 119.25pt;border-top: none;border-left: 1pt solid rgb(243, 243, 243);border-bottom: 1pt solid rgb(243, 243, 243);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><strong><span style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></strong></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;border:none;'><strong><span style='font-size:16px;font-family:  "Sakkal Majalla";'>Terms &amp; conditions link</span></strong></p>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="width: 266.25pt;border-right: 1pt solid rgb(243, 243, 243);border-bottom: 1pt solid rgb(243, 243, 243);border-left: 1pt solid rgb(243, 243, 243);border-image: initial;border-top: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>بتوقيعي أدناه على هذه الاتفاقية ، أوافق على الالتزام بالشروط والأحكام المذكورة أعلاه والالتزام بها والشروط المبينة في الرابط أعلاه:</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:16px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:11px;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:11px;font-family:"Sakkal Majalla";'>*التوقيع بلغة واحدة كافي</span></p>
                </td>
                <td colspan="2" style="width: 275.25pt;border-top: none;border-left: 1pt solid rgb(243, 243, 243);border-bottom: 1pt solid rgb(243, 243, 243);border-right: none;padding: 5pt;height: 22pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";'><span style="font-family:Merriweather;">By my signature below on this Agreement, I will agree to comply with and be bound by the above terms and conditions and the conditions set out on the above link:&nbsp;</span></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";'><span style="font-size:11px;line-height:115%;font-family:  Merriweather;">&nbsp;</span></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";'><span style="font-size:11px;line-height:115%;font-family:  Merriweather;">*Signing in one language is sufficient</span></p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;border:none;'><span dir="LTR" style='font-size:19px;line-height:107%;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
<div align="left" style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0.8in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
    <table dir="rtl" style="width: 5.4e+2pt;margin-left:-34.75pt;border-collapse:collapse;border:none;">
        <tbody>
            <tr>
                <td colspan="2" style="width: 351.75pt;border: 1pt solid white;padding: 5pt;height: 24pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>موقع بواسطة المسوق</span></p>
                </td>
                <td style="width: 192pt;border-top: 1pt solid white;border-bottom: 1pt solid white;border-left: 1pt solid white;border-image: initial;border-right: none;padding: 5pt;height: 24pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";'><strong><span style="font-family:Merriweather;">Signed by the Affiliate</span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="width: 195.75pt;border-right: 1pt solid white;border-bottom: 1pt solid white;border-left: 1pt solid white;border-image: initial;border-top: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>الاسم:</span></p>
                </td>
                <td style="width: 156pt;border-top: none;border-left: 1pt solid white;border-bottom: 1pt solid white;border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:19px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                </td>
                <td style="width: 192pt;border-top: none;border-left: 1pt solid white;border-bottom: 1pt solid white;border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";'><span style="font-family:Merriweather;">Name:</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 195.75pt;border-right: 1pt solid white;border-bottom: 1pt solid white;border-left: 1pt solid white;border-image: initial;border-top: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>التاريخ:</span></p>
                </td>
                <td style="width: 156pt;border-top: none;border-left: 1pt solid white;border-bottom: 1pt solid white;border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:19px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                </td>
                <td style="width: 192pt;border-top: none;border-left: 1pt solid white;border-bottom: 1pt solid white;border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";'><span style="font-family:Merriweather;">Date:</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 195.75pt;border-right: 1pt solid white;border-bottom: 1pt solid white;border-left: 1pt solid white;border-image: initial;border-top: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>التوقيع:</span></p>
                </td>
                <td style="width: 156pt;border-top: none;border-left: 1pt solid white;border-bottom: 1pt solid white;border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:19px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                </td>
                <td style="width: 192pt;border-top: none;border-left: 1pt solid white;border-bottom: 1pt solid white;border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";'><span style="font-family:Merriweather;">Signature:</span></p>
                </td>
            </tr>
            <tr>
                <td colspan="2" style="width: 351.75pt;border-right: 1pt solid white;border-bottom: 1pt solid white;border-left: 1pt solid white;border-image: initial;border-top: none;padding: 5pt;height: 24pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><strong><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></strong></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><strong><span dir="LTR" style='font-size:16px;font-family:"Sakkal Majalla";'>&nbsp;</span></strong></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>موقع بواسطة&nbsp;</span></p>
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><span style='font-size:16px;font-family:  "Sakkal Majalla";'>وبالنيابة عن اتقان</span></p>
                </td>
                <td style="width: 192pt;border-top: none;border-left: 1pt solid white;border-bottom: 1pt solid white;border-right: none;padding: 5pt;height: 24pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";'><strong><span style="font-family:Merriweather;">&nbsp;</span></strong></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";'><strong><span style="font-family:Merriweather;">&nbsp;</span></strong></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";'><strong><span style="font-family:Merriweather;">Signed by&nbsp;</span></strong></p>
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";'><strong><span style="font-family:Merriweather;">for and on behalf of ITCAN</span></strong></p>
                </td>
            </tr>
            <tr>
                <td style="width: 195.75pt;border-right: 1pt solid white;border-bottom: 1pt solid white;border-left: 1pt solid white;border-image: initial;border-top: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:19px;font-family:  "Sakkal Majalla";'>الاسم:&nbsp;</span></p>
                </td>
                <td style="width: 156pt;border-top: none;border-left: 1pt solid white;border-bottom: 1pt solid white;border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;'><span dir="LTR" style='font-size:19px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                </td>
                <td style="width: 192pt;border-top: none;border-left: 1pt solid white;border-bottom: 1pt solid white;border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><span style='font-size:19px;font-family:"Sakkal Majalla";'>Name: Sania Islam</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 195.75pt;border-right: 1pt solid white;border-bottom: 1pt solid white;border-left: 1pt solid white;border-image: initial;border-top: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:19px;font-family:  "Sakkal Majalla";'>المنصب:&nbsp;</span></p>
                </td>
                <td style="width: 156pt;border-top: none;border-left: 1pt solid white;border-bottom: 1pt solid white;border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:  .0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:center;'><span dir="LTR" style='font-size:19px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                </td>
                <td style="width: 192pt;border-top: none;border-left: 1pt solid white;border-bottom: 1pt solid white;border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><span style='font-size:19px;font-family:"Sakkal Majalla";'>Title:Affiliate Marketing Specialist</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 195.75pt;border-right: 1pt solid white;border-bottom: 1pt solid white;border-left: 1pt solid white;border-image: initial;border-top: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span style='font-size:19px;font-family:  "Sakkal Majalla";'>التوقيع:</span></p>
                </td>
                <td style="width: 156pt;border-top: none;border-left: 1pt solid white;border-bottom: 1pt solid white;border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="RTL" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;border:none;'><span dir="LTR" style='font-size:19px;font-family:  "Sakkal Majalla";'>&nbsp;</span></p>
                </td>
                <td style="width: 192pt;border-top: none;border-left: 1pt solid white;border-bottom: 1pt solid white;border-right: none;padding: 5pt;vertical-align: top;">
                    <p dir="LTR" style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";border:none;'><span style='font-size:19px;font-family:"Sakkal Majalla";'>Signature:&nbsp;</span></p>
                    <img src="/signature.png" style="width: 100px; height: 65px; margin-left: -100px; margin-top: -45px;"/>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;border:none;'><span style='font-size:19px;line-height:107%;font-family:"Sakkal Majalla";'>&nbsp;</span></p>
</div>
</template>
<script>
import countryJson from '../../../core/CountryCodes.json'
  export default {
    name: 'TermsAndCondition',
    data() {
        return {
            userDetails: null,
            affiliate_id: null,
            country: null
        }
    },
    mounted(){             
        this.affiliate_id = window.localStorage.getItem("affiliate_id");   
        this.$eventBus.$on("setUserDetail", (data) => {
            this.userDetails = data;
            this.country = countryJson.filter(item=>item.code == data.country)[0]
        });  
    },
    methods: {
    },
  };
</script>
<style>
</style>